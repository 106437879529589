


































































































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  // getMenuTree,
  getMenuTreeApi,
  getpostQuestionListApi,
  getDisease,
  delDiseaseApi,
  getDetailDiseaseApi,
  getpostQuestionListUpdate,
  getItemPageList,
  getItemById,
  getDeleteItem,
  getAddItem,
  getUpdateItem,
  getAddAnswer,
  getDeleteAnswer,
  getUpdateAnswer,
  copyApi
} from '@/serve/evaluatingBefore/evaluatingQuestion';

import {
  getItemData
} from '@/serve/medicalAppointment/itemsManage';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
// import { enums } from '@/utils/commit'
interface DynamicTags {
  quesOptionContent: string;
  sort: string | number;
  quesOptionRelaVOList: QuesOptionRelaVOList[];
  id?: number | string;
}


interface QuesOptionRelaVOList {
  diseaseScore: number | string; //测评指数
  diseaseId: string | number; //疾病系统id
  id?: number;
}
interface AddData<T, U> {
  quesDesc: T;
  quesKey: T;
  quesName: T;
  quesType: T;
  id?: U;
  quesSubjectList: {
    quesSubjectContent: T;
    quesSubjectIsRequired: T;
    quesSubjectType: T;
    sort: T | U;
    id?: U;
    quesId?: U;
    quesOptionList: {
      quesOptionContent: T; //问卷选项内容
      sort: T | U; //排序
      quesOptionRelaVOList: {
        diseaseScore: T; //测评指数
        diseaseId: T | U; //疾病系统id
      }[];
    }[];
  }[];
}

interface TopicData<T, U> {
  check?: T | boolean;
  quesSubjectContent: T;
  quesSubjectIsRequired: T;
  quesSubjectType: T;
  sort: T | U;
  id?: U;
  quesId?: U;
  arr?: (T | U)[];
  item?: T | undefined;
  itemSort?: T | U | undefined;
  diseaseType?: T | undefined;
  scoreModel?: T | U | undefined;
  projectList?: QuesOptionRelaVOList[];

  quesOptionList: DynamicTags[];
  [propName: string]: any;
}
@Component({
  name: 'evaluatingQuestionCreate',
})
export default class EvaluatingQuestionCreate extends Vue {
  public currentPage4 = 1;
  public formLabelWidth = '120px';
  public tableData = [];
  public diseaseType = '';
  public checkQuestion = true;
  public scoreModel = '';
  public riskModel = '';
  public resData: TopicData<string, number>[] = [];
    public enums: any = {
  // 类别
  category: {
    '0': '基础',
    '1': '一般',
    '2': '深度',
    '-1':'通用'
  },
  type: {
    '0': '男',
    '1': '女',
    '-1':'通用'
  },
  scene: {
    '0': '未婚',
    '1': '已婚',
    '-1':'通用'
  },
  //0单选 1多选
  types:{
    '0': '单选',
    '1': '多选'
  }
}
  // public quesOptionRelaVOList: QuesOptionRelaVOList[] = [];
  
  public rulesAdd = {
    type: [{ required: true, message: '请选择问题类型', trigger: 'blur' }],
    title: [{ required: true, message: '请填写问题名称', trigger: 'blur' }],
    sort: [{ required: true, message: '请填写问题序号', trigger: 'blur' }],
  }
  public rulesAddItem = {

    option: [{ required: true, message: '请填写答案名称', trigger: 'blur' }],
    serial: [{ required: true, message: '请填写答案序号', trigger: 'blur' }],
    projectL: [{ required: true, message: '请选择关联项目', trigger: 'blur' }],
  }
  public isShowTopicAddItem = false
  // 添加确定按钮
  public async submitForm() {
    (this.$refs['formAdd'] as ElForm).validate(async (valid) => {
      if (valid) {

        
        this.formAdd.questionnaireId = this.qid
        this.formAdd.answer = "[]"
          
        const res = await getAddItem(this.formAdd);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '添加成功!',
          });
          
          this.isShowTopicAdd = false
          this.editRow(this.qid)
          
          for (const key in this.formAdd) {

            this.formAdd[key] = ''

          }
          
          
          
          
        }




      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  // 自动注入答案
  public async automatic( item: any){
    console.log("执行了",item.id);
    
    const answerList: any = [
      {
        questionnaireItemId: item.id,
          content: "无",
          score:"1",
          sort: 1,//序号
      },
      {
        questionnaireItemId: item.id,
          content: "轻度",
          score:"2",
          sort: 2,//序号
      },
      {
        questionnaireItemId: item.id,
          content: "中度",
          score:"3",
          sort: 3,//序号
      },
      {
        questionnaireItemId: item.id,
          content: "偏重",
          score:"4",
          sort: 4,//序号
      },
      {
        questionnaireItemId: item.id,
          content: "严重",
          score:"5",
          sort: 5,//序号
      }
    ]
    for (let index = 0; index < answerList.length; index++) {
      const res = await getAddAnswer(answerList[index]);
      if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '新增成功!',
          });
         

        }
    }
    this.editRow(this.qid)
  }
 

  // 添加答案确定按钮
  public async submitFormItem() {
    (this.$refs['formAddItem'] as ElForm).validate(async (valid) => {
      if (valid) {

        
        const params: any = {
          questionnaireItemId: this.quesSubjectId,
          content: this.addItemData.option,
          score:this.addItemData.score,
          sort: Number(this.addItemData.serial),//序号
         
        }
        
        if (this.isAdd) {
          const res = await getAddAnswer(params);
        
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '新增成功!',
          });
          this.isShowTopicAddItem = false
          this.editRow(this.qid)
          // this.checkQuestion = true
          
          for (const key in this.addItemData) {

            this.addItemData[key] = ''

          }

        }
        }else{
          params.id = this.dId
          const res = await getUpdateAnswer(params);
        
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '更新成功!',
          });
          this.isShowTopicAddItem = false
          this.editRow(this.qid)
          // this.checkQuestion = true
          for (const key in this.addItemData) {

            this.addItemData[key] = ''

          }

        }
        }
       
        




      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  //题目编辑按钮 
  public async editTopicUpdate(questionItem: TopicData<string, number>, index: number) {
    
    this.formData.quesSubjectList[index].isShowEditUpdate = false
  }

  // 题目提交按钮
  public async postQuestionUpdate(questionItem: TopicData<string, number>, index: number) {


    
    // getUpdateItem

    const params = {
      id: questionItem.id,
      questionnaireId: this.qid,
      title: questionItem.title,
      type: questionItem.type,
      sort: questionItem.sort
    }
    const res = await getUpdateItem(params);
    if (res.code === 200) {
      this.$message({
        type: 'success',
        message: '更新成功!',
      });
      // this.isShowTopicAdd = false
      this.formData.quesSubjectList[index].isShowEditUpdate = true

      this.editRow(this.qid)
      this.getItemPageListApi()
    }
  }

  // 添加取消按钮
  public resetFormAdd() {
    this.isShowTopicAdd = false
  }

  public resetFormAddItem() {
    this.isShowTopicAddItem = false
    for (const key in this.addItemData) {

this.addItemData[key] = ''

}
  }

  public formData: any = {
    name: '',
    description: '',
    category: '',
    type: '',
    scene: '',
    quesSubjectList: this.resData,
    // quesName: '',
    // quesDesc: '',
    // quesType: '检前评估',
    // quesKey: '',

  };
  public tenantName = '';
  public isAdmin = sessionStorage.getItem('isAdmin');
  public restaurants = [];
  public options = [
    {
      value: '0',
      label: '单选',
    },
    {
      value: '1',
      label: '多选',
    },
  ];
  // public typeOptions = [
  //   {
  //     value: '检前评估',
  //     label: '检前评估',
  //   },
  //   {
  //     value: '健康测评',
  //     label: '健康测评',
  //   },
  // ];
  public form = {
    size: 10,
    current: 1,
    keyword: '',
    status:"scl-90"
    // tenantId: '',
  };
  // TODO:风险评估
  public addNum = 1;
  
  public answerPageList = []; //答案列表
  public qid = 0; //问卷id

  public tabNewData = []; //tab选项

  public isShowTopic = true;//题目编辑按钮

  public isShowTopicAdd = false; //添加题目弹窗

  // public isShowEditUpdate = true; // 题目的修改
  public tabList = {
    option: "",//选项
    serial: "",//序号
    score: ""//评分
  }

  public formAdd: any = { //添加题目弹窗参数
    questionnaireId: 0,
    type: "",
    title: "",
    sort: 0,
    status:''
  }

  public formLabelWidths = '120px'

  // TODO:风险评估 end
  // public enums = enums;
  public editNum = 1;
  public dynamicTags: DynamicTags[] = [];
  public inputVisible = false;
  public inputValue = '';
  public value = '';
  public liIsShow = false;
  public dialogFormVisible = false;
  public disease = [];
  public topicSort = '';
  public itemSort = '';
  public typeInter = '';
  public async created(): Promise<void> {
    this.getQuestionList();
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }

  public formList: any = []
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  // public handleSelect(e: any) {
  //   if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
  //   else this.form.tenantId = '';
  // }
  // 问题编辑
  public editTopic() {

    this.isShowTopic = false
  }
  // 问题返回
  public backtrack() {
    this.checkQuestion = true
    this.isShowTopic = true
  }
  // 筛选按钮
  public onSubmit() {
    this.getQuestionList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.tenantName = '';
    // this.form.tenantId = '';
    this.currentPage4 = 1;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 获取项目数据
  public async getDiseaseList() {
    const data = {
      current: 1,
      size: 3000,
      checkItemName: ''
    };
    
    const res = await getItemData(data);
    
    // const newItemData = res.data.records
    // const nullObj = {
    //   id:"",
    //   checkItemName:""
    // }
    // newItemData.unshift(nullObj)
    this.disease = res.data.records;
  }
  // 获取问卷题目列表
  public async getQuestionList() {
    // this.$changNull(this.form);
    
    const res = await getMenuTreeApi(this.form);

    this.tableData = res.data;
  }

// 问题api
public async getItemPageListApi(){
  const params = {
      size: 999,//没有分页，固定死
      current: 1,
      keyword: '',
      questionnaireId: this.qid
    }
    const ress = await getItemPageList(params)
    return ress
   
}

// 问卷api
public async getDetailDiseaseApis(){
  const res = await getDetailDiseaseApi(this.qid);
    return res
    
}

  // 编辑按钮
  public async editRow(id: number): Promise<void> {
    this.typeInter = '编辑';
    this.isShowTopic = true
    this.qid = id

    
    const res = await this.getDetailDiseaseApis()


    this.checkQuestion = false;


    
    const ress = await this.getItemPageListApi()
    

    const newData = res.data;

    newData.quesSubjectList = ress.data.records

    for (let n = 0; n < newData.quesSubjectList.length; n++) {
      newData.quesSubjectList[n].isShowEditUpdate = true
      newData.quesSubjectList[n].isShowAnswer = true
      newData.quesSubjectList[n].tabList = {}
      // newData.quesSubjectList[n].typeBtn = "0"


    }
    this.formData = newData;
    this.addNum = this.formData.quesSubjectList.length + 1;

    // 查询答案列表，遍历出答案 getItemById
    const paramsTD = {
      size: 9999,//没有分页，固定死
      current: 1,
      keyword: '',

    }
    const resTD = await getItemById(paramsTD)
    

    this.answerPageList = resTD.data




    // for (const key in this.formData.quesSubjectList) {

    for (let m = 0; m < this.formData.quesSubjectList.length; m++) {

      this.formData.quesSubjectList[m].clearList = []
      
      this.formData.quesSubjectList[m].projectList = []
      this.formData.quesSubjectList[m].arr = []
      




      const arrNew = []

      
      
      // const answer: any = this.formData.quesSubjectList[m].answers
      
      const answer: any = JSON.parse(this.formData.quesSubjectList[m].answers)


      // 筛选出题目对应的答案列表
      for (let index = 0; index < resTD.data.records.length; index++) {
        
        for (let i = 0; i < answer.length; i++) {
          
          if (resTD.data.records[index].id === answer[i]) {
            
            
            // const newDerivedContent = JSON.parse(resTD.data.records[index].derivedContent)
            // resTD.data.records[index].derivedContent = newDerivedContent
            arrNew.push(resTD.data.records[index])
          }

        }

      }
      
      
      this.formData.quesSubjectList[m].clearList = arrNew









    }


    this.formList = this.formData
    

  }
 
  /**头部查询搜索机构事件 */
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  /** 清空机构名称事件 */
  // public clearName() {
  //   this.form.tenantId = '';
  // }
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 添加题目
  public addTopic() {
    
    this.formAdd.sort = this.addNum
    this.isShowTopicAdd = true


  }
  public isAdd = true
  // 修改选项方法
  public async addItem(index: number, questionItem: any) {
    
    this.formList.quesSubjectList[index].isShowAnswer = true
    this.isShowTopicAddItem = true
    this.isAdd = false
    this.quesSubjectId = questionItem.id
    const optionNew = this.formList.quesSubjectList[index].tabList.option
    const projectLNew = this.formList.quesSubjectList[index].tabList.project
    const serialNew = this.formList.quesSubjectList[index].tabList.serial
    const idNew = this.formList.quesSubjectList[index].tabList.id
    
    this.addItemData.option = optionNew
    this.addItemData.projectL = projectLNew
    this.addItemData.serial = serialNew
    this.addItemData.id = idNew
    
    

    
  }
  // 选项删除按钮
  public async delItem(index: number, questionItem: any) {
    
    const params = {
      id: questionItem.tabList.id
    }
    const res = await getDeleteAnswer(params);

    if (res.code === 200) {
      this.$message({
        type: 'success',
        message: '删除成功!',
      });

      this.editRow(this.qid)
      // this.checkQuestion = true
    }

    // this.refreshData(index);
  }
  // // 这是重置数据
  // public refreshData(index: number) {
  //   const resData = this.formData.quesSubjectList[index];

  //   this.$set(resData, 'item', '');
  //   this.$set(resData, 'itemSort', '');
  //   this.$set(resData, 'diseaseType', '');
  //   this.$set(resData, 'scoreModel', '');
  //   this.$set(resData, 'arr', []);
  //   this.$set(resData, 'projectList', []);
  // }


  // 当输入框值改变的时候 绑定的值也跟着改变
  public scoreChange(value: number, index: number) {
    const resData = this.formData.quesSubjectList[index];
    let distype = '';
    
    for (const key in this.disease) {
      if ((this.disease[key] as any).checkItemName === resData.diseaseType) {
        distype = (this.disease[key] as any).id;
      }
    }
    

    // this.riskModel值是改变疾病框后存起来的值 如果projectList数组里的id值等于riskModel 那么diseaseScore 就等于传入的value（分数）
    for (const key in resData.projectList as QuesOptionRelaVOList[]) {
      if (
        (resData.projectList as QuesOptionRelaVOList[])[key].diseaseId ===
        distype
      ) {
        (resData.projectList as QuesOptionRelaVOList[])[
          key
        ].diseaseScore = value;
      }
    }
  }

  // 题目类型改变
  public itemChange(value: string, index: number) {
    
    this.formData.quesSubjectList[index].type = value
    // const resData = this.formData.quesSubjectList[index];

    // resData.check = value;
    // this.refreshData(index);
    // this.$set(resData, 'projectList', []);
  }
  // 题目保存
  public topicSave(index: number) {
    console.log(index);
  }
  // 题目删除
  public topicDel(index: number, questionItem: any) {

    
    if (questionItem.createTime) {
      (this as any).$messageBox
        .confirm(`该信息已存入库，您确定要删除问卷名称为${questionItem.title}的这一条数据`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(async () => {
          
          const paramsDel = {
            id: questionItem.id
          }
          const res = await getDeleteItem(paramsDel);
         
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            this.formData.quesSubjectList.splice(index, 1);

          }
        });
    } else {
      this.formData.quesSubjectList.splice(index, 1);
    }
  }
  public dId = 0
  // 点击选项列表
  public async tagClick(items: any, index: any, indexs: number | string) {
    const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 1000);
    await this.editRow(this.qid)
   
    // console.log("///",items);
    
    this.dId =Number(items.id) 
    this.formList.quesSubjectList[index].isShowAnswer = false
    this.formList.quesSubjectList[index].tabList.option = items.content
    this.formList.quesSubjectList[index].tabList.score = items.score
    this.formList.quesSubjectList[index].tabList.serial = String(items.sort)
    this.formList.quesSubjectList[index].tabList.id = items.id
    // this.formList.quesSubjectList[index].tabList.typeBtn = "1"

    
    
    
  }
  public addItemData: any = {
    option: '',
    // projectL: [],
    serial: '',
    
    score:0,
    id: ""
  }
  // 加号按钮
  public quesSubjectId = 0
  public tagAddClick(index: number, item: any) {
    // this.formList.quesSubjectList[index].addDisabled = false;
    for (const key in this.addItemData) {

this.addItemData[key] = ''

}
    this.formList.quesSubjectList[index].isShowAnswer = true
    this.isAdd = true
    this.isShowTopicAddItem = true
    this.quesSubjectId = item.id
   
  }
  // 问卷提交按钮
  public async postQuestion() {
    // formData
    (this.$refs['formData'] as ElForm).validate(async (valid) => {
      if (valid) {
        // getpostQuestionListUpdate
        
        if (this.typeInter === "新增") {
          const res = await getpostQuestionListApi(this.formData);
          if (res.code === 200) {
            this.$message({
              message: '提交成功',
              type: 'success',
            });
            this.getQuestionList();
            this.checkQuestion = true;

            this.formData = {
              name: '',
              description: '',
              category: '',
              type: '',
              scene: '',
              quesSubjectList: [],

            }
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            });
          }
        } else {

          const res = await getpostQuestionListUpdate(this.formData);
          if (res.code === 200) {
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.getQuestionList();
            this.checkQuestion = true;
            this.formData = {
              name: '',
              description: '',
              category: '',
              type: '',
              scene: '',
              quesSubjectList: [],

            }
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            });
          }
        }


      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  // 疾病名字筛选
  public filterName(data: string | number) {
    for (const key in this.disease) {
      if ((this.disease[key] as any).id === data) {
        return (this.disease[key] as any).checkItemName;
      }
    }
  }
  // 添加问卷按钮
  public addClearQuestion() {
    this.checkQuestion = false;
    this.formData = {
      name: '',
      description: '',
      category: '',
      type: '',
      scene: '',
      quesSubjectList: [],
    };
    this.typeInter = '新增';
    this.isShowTopic = false
    this.addNum = 1;
  }
  // 删除选项中某项疾病
  public delRisk(index: number, liIndex: number) {
    const resData = this.formData.quesSubjectList[index];
    (resData.projectList as []).splice(liIndex, 1);
    (resData.arr as []).splice(liIndex, 1);
    resData.diseaseType = '';
    resData.scoreModel = '';
    
  }
}
